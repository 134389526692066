<template>
  <div>
    <h3>Title</h3>
    <br>
    <div>
      <el-button :plain="true" @click="openTitle1">message</el-button>
      <el-button :plain="true" @click="openTitle2">success</el-button>
      <el-button :plain="true" @click="openTitle3">warning</el-button>
      <el-button :plain="true" @click="openTitle4">error</el-button>
    </div>
    <br>
    <h3>No title</h3>
    <br>
    <div>
    <el-button :plain="true" @click="open1">message</el-button>
    <el-button :plain="true" @click="open2">success</el-button>
    <el-button :plain="true" @click="open3">warning</el-button>
    <el-button :plain="true" @click="open4">error</el-button>
  </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  components : {
  },
  data() {
    return {
      colorsPrimary: [

      ],

    }
  },
  methods: {
    openTitle1() {
      this.MessageSystem('Có thể thêm hình ảnh và tiện ích phòng ngay sau khi tạo loại phòng thành công', 'message', 'Thông tin bổ sung' )
    },

    openTitle2() {
      this.MessageSystem('Tạo loại phòng thành công, bạn có thể thiết lập bảng giá ngay bây giờ', 'success', 'Tạo phòng thành công')
    },

    openTitle3() {
      this.MessageSystem('Bảng giá bạn muốn tạo đang trùng với bảng giá trước đó, khi lưu bảng giá mới bảng giá cũ sẽ bị thay thế', 'warning', 'Trùng bảng giá')
    },

    openTitle4() {
      this.MessageSystem('Bạn không có quyền truy cập trang', 'error', 'Lỗi 404')
    },
    open1() {
      this.MessageSystem('Có thể thêm hình ảnh và tiện ích phòng ngay sau khi tạo loại phòng thành công', 'message')
    },

    open2() {
      this.MessageSystem('Tạo loại phòng thành công, bạn có thể thiết lập bảng giá ngay bây giờ', 'success')
    },

    open3() {
      this.MessageSystem('Bảng giá bạn muốn tạo đang trùng với bảng giá trước đó, khi lưu bảng giá mới bảng giá cũ sẽ bị thay thế', 'warning')
    },

    open4() {
      this.MessageSystem('Bạn không có quyền truy cập trang', 'error')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-title {
  font-weight: 600;
  font-size: 25px;
  color: #000000;
}

.section-color {
  padding-top: 24px;
  display: flex;
  gap: 40px;
  .block-info {
    padding-top: 10px;

  }
}
.component-preview p {
    font-size: 20px;
    margin: 27px 0 20px;
}

</style>
